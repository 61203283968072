// colours used
export const lightBlue = "#1BAFEE";
export const mainBlue = "#0C71C2";
export const mainWhite = "#ffffff";
export const mainBlack = "#212722";

// website width container
export const siteWidth = "1920px";
export const innerWidth = "1340px";

export const mainFont = `'Roboto', sans-serif`;
export const subFont = `'Schoolbell', cursive;`;

// regex for email validation
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const transHover = `all 0.18s ease-out`;

// Media queries for devices
export const screen = {
  xsmall: `(min-width: 650px)`, // → tablet vertical
  small: `(min-width: 960px)`, // → tablet horizontal
  medium: `(min-width: 1400px)`, // → small laptops
  large: `(min-width: 1530px)`, // → large laptop and regular size desktop
  minilarge: `(min-width: 1278px) and (max-height: 690px)`, // → laptop with short height
  xlarge: `(min-width: 1800px)`, // → large size desktop
  withCursor: `not all and (pointer: coarse)`, // → device with cursor. not touch screen devices
  dropdownWide: `(min-width: 1630px)`,
};
