import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import "../css/global.css";
import styled from "styled-components";
import {
  mainFont,
  siteWidth,
  mainBlack,
  innerWidth,
  screen,
} from "./variables";

const Wrapper = styled.div`
  color: ${mainBlack};
  font-family: ${mainFont};

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 24px;
    @media ${screen.xsmall} {
      padding: 0 48px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }
`;

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <main>{children}</main>
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
